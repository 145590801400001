$env: 1;
#graph-dashboard {
  .filter-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: white;
  }

  .date-picker {
    padding: 16px;
    color: black !important;
    font-size: 16px;
    font-weight: bold;
    width: 200px;

    background: #ffffff;
    border: 1px solid #f4f5f9;
    border-radius: 2px;
  }

  .flat-picker {
    padding: 16px;
    color: black !important;
    font-size: 16px;
    font-weight: bold;

    background: #ffffff;
    border: 1px solid #f4f5f9;
    border-radius: 2px;
    position: absolute;
    height: 68px;
    width: 290px;
    pointer-events: none;
    padding: 0;
    margin: 0;
  }
  .region-selector {
    padding: 16px;
    color: black !important;
    font-size: 16px;
    font-weight: bold;

    background: #ffffff;
    border: 1px solid #f4f5f9;
    border-radius: 2px;
    height: 68px;
    width: 290px;
    pointer-events: none;
    padding: 0;
    margin: 0;

    position: absolute;
    z-index: 1;
  }

  .selected-range {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #171b1e;
  }
  .radialBar-heading {
    position: absolute;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #14172e;
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 67px;
      color: #14172e;
    }
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5b6774;
    }
  }

  .radialBar-rating {
    position: absolute;
    bottom: 22px;
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5b6774;
    }
  }
  .submitted {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5b6774;
    margin: 0;
  }
  .submitted-value {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #5b6774;
    margin: 0;
  }
  .s-round {
    width: 15px;
    height: 15px;
    background: #10a945;
    border-radius: 40px;
    margin-right: 12px;
    margin-top: 3px;
  }
  .s-round-no {
    width: 15px;
    height: 15px;
    background: #1c2346;
    border-radius: 40px;
    margin-right: 12px;
    margin-top: 3px;
  }

  .card-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #14172e;
  }

  .submission-per-region {
    .lhVPMq:not(:last-of-type) {
      border-bottom-style: none !important;
    }

    .rdt_TableHeadRow {
      background: #1c2346;
    }

    .s-table {
      height: 10px;
      overflow: "auto";
      background-color: #787878;
    }
  }

  .sub-full-table {
    overflow-y: scroll;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 33px;
      color: white;
      background-color: #10a945;
      width: 100%;
      text-align: center;
      padding: 5px;
    }

    .id {
      width: 40px;
      height: 40px;
      background: rgba(227, 227, 227, 0.15);
      border-radius: 2px;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #787878;
      }
    }
  }

  .transition {
    transition: all 0.2s;
  }

  .transition:hover {
    transform: scale(1.01);
  }

  .table-chart {
    .apexcharts-xaxis-texts-g {
      display: none !important;
    }

    .apexcharts-grid {
      display: none !important;
    }

    .apexcharts-xaxis {
      display: none !important;
    }
  }
  .apexcharts-menu-icon {
    display: none !important;
  }

  .css-b62m3t-container {
    input {
      width: 310px;
      background: #ffffff;
      height: 55px;
      padding: 0;
      margin: 0;
      border: 0;
      outline: 0;
    }

    .css-qc6sy-singleValue,
    .css-14el2xx-placeholder,
    input {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #171b1e !important;
    }

    input:focus {
      outline: none !important;
    }

    .css-1s2u09g-control {
      border-style: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    input[type="text"] {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #171b1e !important;
    }
  }

  .table-chart {
    height: 100px;
    width: auto;
  }
}

.nupco-Dashboard {
  // background-color: #10A945;
  .region-selector {
    // padding: 16px;

    // background: #ffffff;
    // border: 1px solid #f4f5f9;
    // border-radius: 2px;
    // height: 68px;
    // width: 290px;
    // pointer-events: none;

    // position: absolute;
    // z-index: 1;

    .css-1s2u09g-control {
      padding: 13px;
      color: black !important;
      font-size: 16px;
      font-weight: bold;
    }
    .css-1pahdxg-control {
      padding: 13px;
      color: black !important;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .graph {
    // .transition {
    //   transition: all 0.2s;
    // }
    // .transition:hover {
    //   transform: scale(1.01);
    // }

    &_graphHeader {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      height: 35px;
      align-items: center;
      padding: 10px;
    }

    .radialBar-rating {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .submitted {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #5b6774;
      margin: 0;
    }
    .submitted-value {
      // font-style: normal;
      font-weight: 700;
      font-size: 16px;
      // line-height: 27px;
      color: #5b6774;
      margin: 0;
    }
    .s-round {
      width: 10px;
      height: 10px;
      border-radius: 40px;
      margin-right: 4px;
      margin-top: 5px;
    }
  }
  .transition {
    transition: all 0.2s;
  }

  .transition:hover {
    transform: scale(1.01);
  }
  .card-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #14172e;
  }
}

.card-no-shadow {
  box-shadow: none !important;
  border: 1px solid #f1f1f1;
  margin-bottom: 0 !important;
}

.site-accordion {
  .accordion-item {
    .accordion-header {
      border-bottom: 1px solid #f1f1f1;
      p {
        font-size: 24px;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 9px;
        color: #000000;
      }
    }
    .accordion-collapse {
      .accordion-body {
        padding-top: 16px !important;
        padding-bottom: 0;
      }
    }
  }
}

.custom-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #000000;
}

.rdt_TableHeadRow {
  background: #1c2346 !important;
}

.compliance-dashboard {
  margin-bottom: 20px;
  &_card {
    &_body {
      padding: 18px 28px;
      background-color: #ffffff;
      position: relative;
      height: 100px;
      display: flex;
      justify-content: space-between;
      &-side {
        background-color: #0fa045;
        width: 10px;
        height: inherit;
        position: absolute;
        left: 0;
        top: 0;
      }
      &-content {
        margin-top: 5px;
        .heading {
          color: #6b758a;
          font-size: 18px;
          font-weight: 500;
        }
        .count {
          color: #261d56;
          font-weight: 500;
          font-size: 28px;
        }
      }
    }
  }
}

.dynamic-form-dashboard {
  .date-picker-side {
    background-color: #e2e4e9 !important;
    font-size: 14px;
    font-weight: 500;
    color: #464f60;
  }

  .date-picker {
    background-color: white !important;
    position: relative;
  }
  .filter-icon {
    position: absolute;
    right: 10px;
    top: 7px;
  }
  .dash-heading {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
  }
  .dash-sub-heading {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }
  .section-head {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #000000;
  }

  .rdt_TableCol {
    background-color: #e2e4e9 !important;
    border-radius: 8px !important;
  }
  .rdt_TableHeadRow {
    border-radius: 8px !important;
    background-color: #e2e4e9 !important;
    border: none !important;
  }

  .widget {
    // background-color: #0fa045;
    // min-height: 150px;
    border-radius: 10px !important;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    // justify-content: space-evenly;
  }

  .header {
    margin: 0px 20px;
    .name {
      color: #525866;
      font-size: 16px;
    }
    .value {
      color: #0a0d14;
      font-size: 24px;
    }
  }

  .pill-section {
    .pill {
      .standard-obl-pill-dot {
        border-radius: 100%;
        margin-left: 0px;
        margin-right: 10px;
        width: 7px !important; /* Adjust this value to change the size of the circle */
        height: 7px !important; /* Adjust this value to change the size of the circle */
        // background: #176448;
      }
      .standard-obl-pill {
        position: relative;
        display: inline-block;
        padding: 5px 15px; /* Adjust this value to create space for the circle */
        border-radius: 6px;

        // background: #cbf5e5;
        // color: #176448;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 !important;
      }
    }

    .pill-value {
      color: #0a0d14;
      font-size: 20px;
    }
  }

  .standard-pill-table {
    padding: 3px 10px !important; /* Adjust this value to create space for the circle */
    border-radius: 999px !important;
  }
  .image {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .count {
    font-size: 22px;
    font-weight: 800;
    margin-left: 20px;
  }
  .text-content {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
  .percent {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
}

.p-tabview-ink-bar {
  display: none !important;
}

.p-tabview-panels {
  background-color: transparent !important;

  // margin: 0 !important;
  // margin-top: 20px;
  padding: 0 !important;
}

.p-tabview .p-component {
  margin: 0 !important;
  padding: 0 !important;
}

.p-tabview-nav-content {
  // margin: 0px calc(100vh - 67%);
  width: 100% !important ;

  // calc(2rem + 4.45rem) 2rem 0 2rem
}

.sc-fzoLsD {
  border: none !important;
}

.icmOyL {
  background-color: #f6f8fa !important;
}

.p-tabview-nav-btn {
  height: 50px !important;
  box-shadow: none !important;

  .p-icon {
    color: #0fa045 !important;
  }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #0fa045 !important;
  color: #0a0d14 !important;
}

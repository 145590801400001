$env: 1;
.modal_body {
  // float: right !important;
  .hr {
    width: 100%;
    height: 1px;
    background: var(--stroke-soft-200, #E2E4E9);
  }
  .grouped_value {
    // float: right;
    color: var(--text-main-900, #0A0D14);
    // text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    padding-block: 20px;
    padding-inline: 15px;
  }

  .names_row {
    margin-block: 10px;;
  }
  .names {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1px solid var(--stroke-soft-200, #E2E4E9);
    background: var(--bg-white-0, #FFF);
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: calc(100vw - 20vw) !important;
  }
}
$env: 1;
$background: #fff;
$primary-green: #008556;
$link-blue: #375dfb;
$border: #e2e4e9;
$light-text: #868c98;
$text-color: #0a0d14;
$info-grey: #525866;
$content-background: #f6f8fa;

.valid,
.invalid {
  span {
    margin-left: 10px;
  }
}

.swal2-modal {
  border-radius: 18px !important;
  padding: 30px !important;
  min-width: 800px;

  @media (max-width: 600px) {
    min-width: 300px;
  }

  .swal2-actions {
    width: 100%;
    justify-content: end;
    flex-direction: row-reverse;
    gap: 30px;

    .swal2-confirm {
      background-color: $primary-green !important;
      color: white;
      border: none;
      border-radius: 10px;
      width: 179px;
      height: 48px;
      margin-right: 20px;
    }

    .swal2-cancel {
      background-color: white !important;
      color: $primary-green !important;
      border: 1px solid $primary-green !important;
      border-radius: 10px;
      width: 179px;
      height: 48px;
    }
  }
}
.institute_container {
  .emailLabel {
    color: $text-color !important;
    font-size: 15px;
  }
  .table_container {
    padding: 10px;
    // padding-top: 40px;

    border-radius: 20px;
    border: 1px solid $border;

    /* regular-shadow/small */
    box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;

    .tableCol {
      border-bottom: 1px solid $border;
      padding-top: 20px;
      // margin: 20px 0px;
      text-align: start !important;
      align-items: center;
      .title {
        color: $light-text !important;
        margin-bottom: 10px;
      }

      .value {
        color: $text-color !important;
        margin: 0;
        margin-bottom: 20px;
      }

      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}
.authLayout_container {
  max-height: 100vh !important;
  width: 100%;
  background-color: $background;
  overflow: hidden;
  display: flex;
  overflow: auto;

  @media (max-width: 640px) {
  }

  .form_section {
    width: 45%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // justify-items: center;
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 530px) {
      padding: 0px 30px;
    }

    gap: 10px;

    .card-body {
      // width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .card-content {
        background-color: white;
        padding: 30px;
        border-radius: 20px;
        height: max-content;
        .login_form {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    .custom-dropdown {
      margin-top: -10px;
      .dropdown-toggle::after {
        filter: invert(35%) sepia(10%) saturate(150%) hue-rotate(190deg)
          brightness(100%) contrast(90%) !important;
      }
    }
    .info-content {
      font-size: 12px;
    }

    .forget_password {
      font-size: 14px;
      font-weight: 500;
      color: $info-grey;
      text-decoration: underline;
    }
    .register_facility {
      font-size: 14px;
      font-weight: 500;
      color: $link-blue;
      .input-group {
        border-radius: 50px !important;
      }
    }
    .line-devider {
      background-color: $border !important;
      margin-bottom: 20px;
      margin-top: 0px !important;
    }

    .authButton {
      background-color: $primary-green !important;
      border-radius: 10px !important;
      border: none !important;
    }

    .discardButton {
      background-color: white !important;
      border-radius: 10px !important;
      color: $info-grey !important;
      border: 1px solid $info-grey !important;
    }
    .backButton {
      color: $info-grey !important;
      border-radius: 10px !important;
      border-color: $border !important;
    }

    .supportLink {
      color: $link-blue;
      //   text-decoration: underline;
      margin-top: 15px;
    }
    .fotor {
      font-size: 14px;
      font-weight: 400;
      color: $info-grey;
      margin: 0px 24px;
      @media (max-width: 600px) {
        margin: 0px !important;
      }
    }

    .login_form {
      width: 392px;
      display: flex;
      flex-direction: column;
      background-color: none !important;

      @media (max-width: 600px) {
        width: 320px;
      }
      .heading {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 500;
      }
      .info {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 30px;
      }
      .login-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        .heading {
          margin-top: 20px;
          font-size: 24px;
          font-weight: 500;
        }
        .info {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 30px;
        }
      }

      .lables {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .content_section {
    width: 55%;
    margin: 10px;
    background-color: $content-background;
    border-radius: 20px;
    height: 98vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: end;
    align-items: center;
    gap: 110px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
      display: none;
    }

    .text_content {
      color: $text-color;
      width: 584px !important;
      height: 144px !important;

      .head {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.015em;
        text-align: center;
      }
      .info {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.011em;
        text-align: center;
      }
    }
    .image {
      width: 85%;
    }
  }
}

.loginFields {
  height: 40px !important;
  border-color: $border;
  box-shadow: 0px 1px 2px 0px #e4e5e73d;
  // background-color: transparent !important;
  border-radius: 10px !important;
}

.loginfields-icon-invalid {
  svg {
    path {
      fill: #ea5455;
    }
  }
}

.main-header {
  margin: 24px 44px;

  @media (max-width: 600px) {
    margin: 20px 10px;
  }
}

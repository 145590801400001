$env: 1;

 
 
 
 
 
 [dir] .fr-box.fr-basic  {
    z-index: 0 !important;
  }
  .fr-second-toolbar #fr-logo span,
.fr-second-toolbar #fr-logo svg {
  display: none !important;
}
$env: 1;
// @import "../../../../@core/scss/base/bootstrap-extended/include";
// @import "../../../../@core/scss/base/components/include";



.schedule_self_ass {
  border-radius: var(--X-Large, 8px) !important;
  border: 1px solid var(--stroke-soft-200, #E2E4E9) !important;
  background: var(--bg-white-0, #FFF) !important;

  .react-select__control {
    overflow: hidden;
    color: var(--text-soft-400, #868C98);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.084px;
  }

  .form_label {
    color: var(--text-main-900, #0A0D14);
    text-align: right;
    font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
  
    /* Label/Small */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.084px;
  }
  .all_medicines {
    cursor: pointer;
    color: var(--primary-base, #375DFB);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
  }
  .dates {
    margin-top: 27px;
    position: relative;
    .calander_icon {
      position: absolute;
      right: 25px;
      top: 44px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .form-control {
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--stroke-soft-200, #E2E4E9);
    background: var(--bg-white-0, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  }
  .form-control::placeholder {
    overflow: hidden;
    color: var(--text-soft-400, #868C98);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.084px;
  }
  .css-k3ockt-control {
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--stroke-soft-200, #E2E4E9);
    background: var(--bg-white-0, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  }
  .css-1u57jws-control {
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--stroke-soft-200, #E2E4E9);
    background: var(--bg-white-0, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  }
  
  .selected_options {
    display: flex;
    padding: 3px 5px 4px 8px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 3px;
    border-radius: var(--radius-6, 6px);
    border: 1px solid var(--stroke-soft-200, #E2E4E9);
    background: var(--bg-white-0, #FFF);
    
    .text {
      color: var(--text-sub-500, #525866);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
    .cross {
      cursor: pointer;
    }
  }
  
}
.steper-plus-minus {
  display: flex;
  height: 30px;
  width: 30px;
  font-size: 30px;
  margin: 0;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.steper-plus-minus:hover {
  transform: scale(1.1);
}

.stepper-text-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1c2346;
  text-align: center;
  margin: 0;
}
.auto_save {
  border-radius: var(--X-Large, 8px) !important;
  border: 1px solid var(--stroke-soft-200, #E2E4E9) !important;
  background: var(--bg-white-0, #FFF) !important;

  .button_style {
    padding-inline: 12px;
    padding-block: 8px;
  }
}



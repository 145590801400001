$env: 1;
@import "../../../@core/scss/base/bootstrap-extended/include";
@import "../../../@core/scss/base/components/include";

.signature {
  // border: 2px solid;
  // border-color: $primary;
  // border-radius: 5px;

  width: 50%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

#AuditDetails {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: #261d56;
  }
}

#user-details {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #525866;
  }

  .item-value {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6e6b7b;
  }
}

#medication-details {
  // h2 {
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 39px;
  //   color: #261d56;
  // }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #000000;
  }
}

.pharmacy-available {
  background-color: $menu-dark-bg-color;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 5px;
  &-content {
    background-color: #fafafafa;
    padding: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: $menu-dark-bg-color;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.badge-show {
  // background-color: red;
  padding: 2px 4px;
  color: white;
  border-radius: 7px;
  font-weight: 500;
  font-size: 11px;
}

.dottedBorderTop {
  border-top: 1px dotted black;
}

.risk-complaince-details {
  hr {
    color: #d2d4d8 !important;
  }

  .showmore {
    color: #375dfb;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    span {
      margin-left: 19px;
      margin-right: 29px;
    }
  }

  .title {
    color: #868c98;
    font-size: 14px;
  }
  .value {
    color: #0a0d14;
    font-weight: 500;
    font-size: 16px;
  }

  .timeline {
    list-style: none;
    padding: 0;
    position: relative;
  }

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    left: 20px;
    margin-left: -1.5px;
    border-left: 3px dashed #d4d4d4; /* Change the background to a dashed border */
  }

  .timeline > li {
    margin-bottom: 20px;
    position: relative;
  }

  .timeline > li .timeline-badge {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 0px;
    margin-right: 10px;
    background-color: #f6f8fa;
    border-radius: 50%;
  }

  .timeline > li .timeline-panel {
    position: relative;
    margin-left: 60px;
    padding: 0 20px 0 10px;
    // border-radius: 5px;
    // background: #f5f5f5;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .badge-custom {
    border-radius: 40px !important;
    padding: 4px 15px !important;
    font-weight: 600;
    font-size: 12px;
    max-width: fit-content;
  }

  .badge-minor {
    background-color: #cbf5e5; /* Light Grey */
    color: #176448; /* Black Text */
  }
  .badge-light {
    background-color: #fbdfb1; /* Light Grey */
    color: #693d11; /* Black Text */
  }

  .badge-warning {
    background-color: #ffdac2; /* Warning Yellow */
    color: #6e330c; /* Black Text */
  }

  .badge-danger {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  .badge-catasatrophic {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  /* Additional Custom Badge Classes */

  .badge-secondary {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  .badge-legend-light {
    background-color: #f1f1f1; /* Same as .badge-light for Legends */
    color: #000; /* Black Text */
  }

  .badge-legend-warning {
    background-color: #ffc107; /* Same as .badge-warning for Legends */
    color: #000; /* Black Text */
  }

  .badge-legend-danger {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }
  .badge-legend-catasatrophic {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  .border-rig {
    border-right: 0.5px solid #d2d4d8;
  }
}
